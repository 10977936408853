import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

// Helpers
import colors from '../../theming/colors';

import getSiblings from '../../helpers/getSiblings';

const StyledHeader = styled.header`
    position: fixed;
    display: flex;
    justify-content: space-around;
    top: 40px;
    left: 0;
    padding: 0 100px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    /* flex-direction: row-reverse; */
    z-index: 20;

    & .menubar__toggle {
        color: ${colors.secundaryColor};
        float: right;
        line-height: 50px;
        font-size: 24px;
        cursor: pointer;
        display: none;
    }
`

const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    height: 62.66px;
`

const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    float: right;

    & .menubar__root-list {
        margin: 0;
        padding: 0; 
        display: flex;

        & .menubar__menu-item {
            display: flex;
            list-style: none;
            position: relative;

            & .menubar__menuitem-link {
                display: block;
                padding: 1rem;
                color: #595959;
                text-decoration: none;
                text-transform: uppercase;

                &:hover 
                {
                    color: ${colors.secundaryColor};
                    box-shadow: inset 0 -3px 0 0 ${colors.secundaryColor};
                    fill: currentColor;
                }

                &.active-item
                {
                    color: ${colors.secundaryColor};
                    box-shadow: inset 0 -3px 0 0 ${colors.secundaryColor};
                    fill: currentColor;
                }
            }

            & .menubar__border {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border-right: 1px solid rgba(0,0,0,.2);
            }

            & .menubar__submenu-list {
                position: absolute;
                left: 0;
                background: #fff;
                padding: 0;
                z-index: 9999;
                display: none;
                width: 100%;
                min-width: max-content;
            }
        }

        & .menubar__menu-item.active .menubar__submenu-list  {
            display: block;

            & .menubar__submenu-item {
                display: block;
                width: 100%;
            }
        }

        & .menubar__parent-sub-menu-item::before {
            content: '\f107';
            font-family: fontAwesome;
            position: absolute;
            line-height: 57px;
            color: #595959;
            right: 0px;
        }

        & .active-item.menubar__parent-sub-menu-item::before {
            content: '\f106';
        }
    }
`

const Brand = styled.div`

    & .menubar__brand-border {
        border-right: 1px solid rgba(0,0,0,.2);
        margin: 0 1rem;
    }

    & .menubar__logo {
        /* opacity: .7; */
        width: 180px;
        height: auto;
    }

    & .menubar__logo:hover {
        opacity: 1;
        cursor: pointer;
    }

    & .menubar__logo-mobile {
        width: 63px;
        height: auto;
        display: none;
    }
`

const Search = styled.div`
    margin-left: auto;

    & .menubar__search {
        opacity: .7;
        padding: 1rem;

        &:hover{
            opacity: 1;
            cursor: pointer;
            box-shadow: inset 0 -3px 0 0 ${colors.primaryColor};
            fill: currentColor;
        }
    }
`

const Header = ({items, logo, logo2, logoMobile, search, hashLink = false}) => {

    const [active, setActive] = useState(false);

    /**
     * @description Le setea o quita la clase active al link del menu
     * @returns void
     */
     const handleOnClick = (myRef) => {
        const li = myRef.current;
        // Listas hermanas a la seleccionada
        let siblingsList = getSiblings(li);
        // Quitamos todas las activas de las hermanos activos
        siblingsList.forEach( item => {
            item.classList.remove('active-item');
            item.querySelector('a').classList.remove('active-item');
        });
        // toggle al current li
        li.classList.toggle('active-item');
        // toggle al current a
        li.querySelector('a').classList.toggle('active-item');
        setActive(!active);
    }

    /**
     * @description Construye los subitems
     * @param {object[]} subItems 
     * @returns JSX.Element[]
     */
     const buildListSubItems = subItems => {
        return subItems.map( (subItem, index) => {
            return <li className="menubar__submenu-item" key={`menubar__submenu-item${index}`}>
                <Link className="menubar__menuitem-link" to={subItem.routerLink}>
                    {subItem.label}
                </Link>
            </li>
        });
    }

    /**
     * @description Construye el ul para los subitems
     * @param {object[]} subItems 
     * @returns 
     */
     const buildUlSubItems = subItems => <ul className='menubar__submenu-list'>{ buildListSubItems(subItems)}</ul>;

    /**
     * @description Construye los items que vienen en los props.
     * @returns JSX.IntrinsicElements.li
     */
    const buildListItems = () => {
        return items.map( (item, index) => {
            const myRef = React.createRef();
            if( item.items && item.items.length ) {
                return <li className='menubar__menu-item menubar__parent-sub-menu-item'
                           onClick={() => handleOnClick(myRef)}
                           ref={myRef}
                           key={`menubar__menu-item${index}`}>
                    {!hashLink && <Link className='menubar__menuitem-link' to="/">
                        {item.label}
                    </Link>}
                    {buildUlSubItems(item.items)}
                </li>
            } else {
                return <li className="menubar__menu-item" key={`menubar__menu-item${index}`} onClick={() => handleOnClick(myRef)} ref={myRef}>
                    {!hashLink && <Link className="menubar__menuitem-link" to={item.routerLink}>
                        {item.label}
                    </Link>}
                    {hashLink && <HashLink 
                                            smooth
                                            className='menubar__menuitem-link' 
                                            to={item.routerLink}
                                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        {item.label}
                    </HashLink>}
                    {index + 1 !== items.length && <span className='menubar__border'></span>}
                </li>
            }
        });
    }

    return (
        <>
            <StyledHeader className='header'>
                <Brand className='menubar__brand'>
                    <img alt="braganza"
                        src={logo}
                        className="p-mr-2 menubar__logo"/>
                    {logoMobile && <img alt="braganza"
                        src={logoMobile}
                        className="p-mr-2 menubar__logo-mobile"/>}
                    {logo2 && <>
                        <span className='menubar__brand-border'></span>
                        <img alt="braganza"
                            src={logo2}
                            height="25"
                            className="p-mr-2 menubar__logo"/>
                    </>
                            }
                </Brand>
                <StyledNav className={`menubar ${ active && 'active' }`}>
                    <ul className='menubar__root-list'>
                        { buildListItems() }
                    </ul>
                </StyledNav>
                {search && <Search className='menubar__right'>
                        <div className="menubar__search">
                            <img alt="search"
                                src={search}
                                height="25"
                                className="p-mr-2 menubar__search-img"/>
                        </div>
                </Search>} 
                <div className="menubar__toggle"><i className="fa-solid fa-bars" onClick={() => setActive(!active)}></i></div>
            </StyledHeader>
            <StyledDiv></StyledDiv>
        </>
    );
}

export default Header;