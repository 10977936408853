const colors = {
    primaryColor: '#ee1b2e',
    secundaryColor: '#008d39',
    tertiaryCorlor: '#49ad6e',
    darkColor: '#121212',
    lightColor: '#fff',
    primaryColorRgba: 'rgba(238, 27, 46,.8)',
    secundaryColorRgba: 'rgba(0, 141, 57,.8)',

}

export default colors;