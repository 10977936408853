const constans = {
    user : {
        contactUs : 'form-contact/send-email'
    },
    messages : {
        success : 'Solicitud enviada exitosamente.',
        error   : 'No se pudo enviar su solicitud.'
    }
}

export default constans;